* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities";

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200..800&display=swap");

/* @tailwind base;
@tailwind components;
@tailwind utilities; */
/* custom fonts */
@font-face {
  font-family: "Ethnocentric";
  src: url("/src/fonts/ethnocentric_rgtwo.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");

.app-container {
  display: flex;
}

.side-bar {
  width: 250px;
  /* Adjust sidebar width as needed */
  background-color: #f0f0f0;
  /* Sidebar background color */
}
.content {
  flex: 1;

  /* padding: 20px; */
  /* Add padding for spacing */
  background-color: #f9f9f9;
  margin-inline-start: 300px;

  /* Content background color */
}

.content_two {
  flex: 1;

  /* padding: 20px; */
  /* Add padding for spacing */
  background-color: #f9f9f9;
  /* margin-left: 100px; */

  /* Content background color */
}
 
@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    /* max-width: 1900px; */
    max-width: 1200px;
    /* max-width: 1050px; */
  }
}

@media (min-width: 1200px) {
  .container
  /* .container-lg,
  .container-md,
  .container-sm,
  .container-xl  */ {
    padding-left: 0 !important;
    padding-right: 0 !important;
    /* max-width: 1200px; */
    /* max-width: 1200px; */
    /* max-width: 1045px !important;  */
    /* max-width: 100% !important;  */
    /* max-width: 100%; */
    /* max-width: 100%; */
    /* width: 100%; */
    /* Prevent horizontal overflow */
    max-width: 1000px;
    overflow-x: hidden;
    /* Optional: Ensure the container fits within the viewport */
    box-sizing: border-box;
  }
  .container-admin {
    max-width: 100%;
    overflow-x: hidden;
    /* Optional: Ensure the container fits within the viewport */
    box-sizing: border-box;
  }

  .container-lg {
    /* max-width: 1900px; */
    max-width: 1200px !important;
    /* max-width: 100% !important; */
    padding: 40px !important;
  }
}

/* Styles for mobile devices (up to 768px wide) */
@media only screen and (max-width: 767px) {
  .content {
    margin-inline-start: 0;
  }
  .container {
    margin-top: 6%;
  }
  /* .container-sm{
  max-width: 355px ;
} */
  .container {
    max-width: 355px !important;
    /* max-width: 767px ;  */
  }
}

/* Styles for tablets (between 768px and 1024px wide) */
@media only screen and (min-width: 769px) and (max-width: 1023px) {
  .container {
    margin-top: 4%;
  }
  .content {
    margin-inline-start: 0;
  }
}

.css-1n7v3ny-option {
  background-color: transparent !important;
}

.css-1n7v3ny-option:hover {
  background-color: #f1f1f1 !important;
}

.css-yt9ioa-option:hover {
  background-color: #f1f1f1 !important;
}

.button-section-2 {
  /* margin-left: 5px !important; */
}

.format-button-generative-ai {
  width: 20%;
}

@media only screen and (max-width: 768px) {
  .format-button-generative-ai {
    width: 100%;
  }
}
.format-button-generative-ai .format-btn {
  border-radius: 5px !important;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh !important;
  flex-direction: column;
}
.text-primary {
  color: #c00eae !important;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.json-viewer {
  font-family: monospace;
  white-space: pre-wrap;
}

.container-right {
  /* padding-top: 20px !important; */
  padding-left: 40px !important;
  padding-right: 40px !important;
  padding-bottom: 40px !important;
}

/* scroll */
/* For WebKit browsers (Chrome, Safari, Edge) */
::-webkit-scrollbar {
  width: 12px;
  /* Width of the entire scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Color of the track */
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Color of the scrollbar handle */
  border-radius: 6px;
  /* Roundness of the scrollbar handle */
  border: 3px solid #f1f1f1;
  /* Space around the handle */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  /* Handle color on hover */
}

.loginScreen {
  margin-left: 0 !important;
  width: 80% !important;
}

.error {
  color: red;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}
.admin-button {
  height: auto;
  border-radius: 5px;
  font-family: "Montserrat", sans-serif;
  color: #fff;
  background-color: #c00eae;
  font-size: 16px;
  font-weight: 700;
  padding: 7px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #c00eae;
  transition: background-color 0.3s ease, border-radius 0.3s ease,
    border 0.3s ease, color 0.3s ease;
}
.admin-button:hover {
  background-color: transparent;
  border: 2px solid #c00eae;
  color: #c00eae;
  border-radius: 3px;
  font-size: 16px;
  font-weight: 700;
  padding: 7px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.action-button {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  padding: 0.125rem;
  background-color: transparent;
  border-color: rgb(233, 233, 233);
  border-width: 1px;
  border-radius: 0.375rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.75rem;
  height: 1.75rem;
  cursor: pointer;
}
.action-button svg {
  color: #c00eae;
}
.table-btn {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
}
.action-button:hover {
  border-color: #c00eae;
}

.title-admin {
  color: #c00eae;
  font-weight: 500;
}

.btn:hover {
  color: black;
}

/* .image-upload-preview {
   display: flex;
   flex-direction: column;
   align-items: center;
 }

 .choose-file-label {
   cursor: pointer;
 }

 .image-preview img {
   border: 2px solid #ddd;
   border-radius: 5px;
   margin-top: 15px;
 } */

textarea::placeholder {
  font-size: 14px;
}

.editor-scroll-enable {
  /* overflow-y: scroll; */
}

/* .ql-container{

  min-height: 400px !important;
} */

/* .ql-container .ql-editor{
  min-height: 
  450px;
} */


.ql-syntax {
  background: #23241F;
  color: white;
  padding: 1em;
  border-radius: 5px;
  overflow-x: auto;
  font-family: 'Courier New', Courier, monospace;
  white-space: pre-wrap; /* Ensures that long lines wrap correctly */
}