.container {
  padding: 40px;
}
.randomUuidDisplay {
  display: flex;
  justify-content: center;
  align-items: center;
}
.uuid-display-heading {
  font-size: 42px !important;
}
.uuid-cpy {
  display: flex !important;
  justify-content: space-around;
  align-items: center;
}
.uuid-cpy svg {
  width: 30px;
  height: auto;
  margin-left: 5px !important;
}
.uuid-cpy svg:hover {
  color: #c00eae;
  cursor: pointer;
  width: 30px;
  height: auto;
  margin-left: 5px !important;
}
.heading-formatter {
}

.heading-formatter-text {
  font-size: 22px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  color: #c00eae;
}

.heading-paragraph {
  font-size: 17px;
  font-weight: 400;
  margin-top: 10px;
  font-family: "Poppins", sans-serif;
  color: #30323e;
}

.card-formatter {
  background-color: white;
  padding: 3%;
}

.copy-paste label {
  font-size: 18px;
  font-weight: 600;
  /* margin-bottom: 10px; */
  font-family: "Montserrat", sans-serif;
  line-height: 25.98px !important;
}

.text-area-formatter {
  /* min-height: 200px !important; */
  width: 100%;
  padding: 2%;
  color: #000;
  border: 2px solid;
  border-radius: 10px;
  background-color: #fff;
  border-color: #e4e4e4;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.input-area-formatter {
  width: 100%;
  padding: 2%;
  color: #000;
  border: 2px solid;
  border-radius: 4px;
  background-color: #fff;
  border-color: #e4e4e4;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.custom-dnd {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.uploadIcon {
  height: 40px;
}

.custom-input-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 24.2px;
  font-family: "Montserrat", sans-serif;
  color: #202020;
}

.browse-file-button {
  width: 100px;
  height: 34px;
  border-radius: 14px;
  color: #202020;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  border: 2px solid #cbd0dc;
  padding: 24.92px, 51.4px, 24.92 51.4;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.browse-file-button:hover {
  background-color: #c00eae;
  color: #fff;
  border: 2px solid #c00eae;
}

.main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.dropzone-container {
  width: 525px;
  height: 225px;
}

.button-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.label-drop-down {
  font-size: 13px;
  line-height: 44.98px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  margin-top: 15px;
}

.button-section-2 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* margin-top: 2%; */
  /* padding-left: 0.7rem; */
}

.social-btn-sec {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 15px;
  margin-left: 5px;
  align-items: center;
}

.social-btn-sec2 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5px;
  align-items: center;
}
.button-section-epoch {
  display: flex;
  justify-content: center !important;
  align-items: center;
  padding-top: 4%;
}

.format-btn {
  width: 100% !important;
  /* width: 100%; */
  height: auto;
  border-radius: 5px;
  font-family: "Montserrat", sans-serif;
  color: #fff;
  background-color: #c00eae;
  font-size: 12px;
  font-weight: 700;
  padding: 14px;
}
.format-btn {
  margin-bottom: 0px !important;
}
.format-btn-difference {
  /* width: 183px; */
  width: 100% !important;
  height: auto;
  border-radius: 25px !important;
  font-family: "Montserrat", sans-serif;
  color: #fff;
  background-color: #c00eae;
  font-size: 12px;
  font-weight: 700;
  padding: 14px;
}
.difference-checker {
  display: flex;
  justify-content: center;
  align-items: center;
}
.formatted-heading {
  color: #c00eae;
  font-size: 18px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  text-align: center;
  /* padding: 4%; */
}

.formatted-heading-epoch {
  color: #c00eae;
  font-size: 18px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  text-align: start;
  padding: 2%;
}

.editor_holder_button {
  display: flex;
  justify-content: end;
  align-items: center;
  /* margin-bottom: 20px; */
  right: 6%;
  top: 14%;
}

.editor_holder_button_bg {
  background-color: #c00eae;
  border-radius: 5px;
  width: 35px;
  height: 35px;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.editor_holder_button button svg {
  width: 31.7px;
  height: 33px;
  color: #fff;
}

.main-content-container {
  margin-top: 4%;
}

.main-heading {
  font-size: 27px;
  font-weight: 700;
  line-height: 56.22px;
  color: #c00eae;
  font-family: "Montserrat", sans-serif;
}

.main-heading-paragraph {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  line-height: 29.23px;
  color: #30323e;
}

.sub-heading {
  margin-bottom: 20px;
}

.sub-list-ul {
  padding-left: 4%;
}

.sub-list-ul li {
  list-style-type: square;
  font-size: 17px;
  font-family: Manrope, sans-serif;
  font-weight: 400;
  color: #30323e;
}

.preview-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 1400px) {
  .formatted-heading {
    padding-bottom: 8px;
  }
}

.error-message {
  color: #924040;
  background-color: #fde1e1;
  border-color: #fcd2d2;
  padding-right: 3.75rem;
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0;
  transition: opacity 0.15s linear;
}

.closeButton svg {
  font-size: 10px;
  padding: 1.05rem 1.25rem;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  cursor: pointer;
  color: #000;
  box-sizing: content-box;
  width: 2em;
  height: 2em;
}

.epoch-converter-input {
  width: 200px;

  display: block;
  width: 100%;
  padding: 0.47rem 0.75rem;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
}

.epoch-select {
  display: block;
  border: 1px solid #ced4da;
  width: 100%;
  padding: 0.47rem 1.75rem 0.47rem 0.75rem;
  /* padding: .47rem .75rem; */
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
}

.qr-code-image-wrapper {
  width: 100%;
  border: solid 1px darkgrey;
  min-height: 360px;
  border-radius: 8px;
  /* margin: 10px; */
  padding: 5%;
}
.uuid-code-image-wrapper {
  width: 100%;
  border: solid 1px darkgrey;
  min-height: 360px;
  border-radius: 8px;
  margin: 0px !important;
}
.qr-code-image-wrapper img {
  vertical-align: middle;
}

.uuid-display {
  font-weight: 600;
}

.success-message {
  color: #a8bf93;
  background-color: #e0edd3;
  border-color: #74d673;
  padding-right: 3.75rem;
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0;
  transition: opacity 0.15s linear;
}
.social-media-button svg {
  /* color: #c00eae; */
  cursor: pointer;
}
/* .social-media-button svg:hover{
    color: #ffffff;
 } */

.social-media-text {
  font-size: 21px;
  font-weight: 500;
}
.facebook {
  color: #3b5998;
}
.twitter {
  /* background-color:  #000000;
    border-radius: 50%; */
}
.twitter svg {
  color: #000000;
}
.whatsapp {
  color: #25d366;
}
.piniest {
  color: #cb2128;
}
canvas {
  height: auto !important;
  width: 100% !important;
}

@media only screen and (max-width: 768px) {
  .qr-code-image-wrapper {
    width: 100% !important;
    min-height: 270px !important;
    /* margin: -2px; */
    padding: 5%;
  }
  .format-btn {
    width: 100% !important;
  }

  .mobile-responsive-col {
  }

  .button-section-2 {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin-top: 2% !important;
    padding: 5%;
  }

  .format-btn {
    margin-bottom: 20px;
  }

  .button-mob {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
  }

  .mobile-res-row {
    margin-bottom: 5px;
  }

  .copy-paste label {
    font-size: 16px;
  }

  .custom-input-text {
    font-size: 12px;
  }

  .mobile-responsive-col {
    margin-bottom: 10%;
  }

  .mobile-responsive-view-btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .main-heading {
    font-size: 20px !important;
    font-weight: 700;
    line-height: 25.22px;
    color: #c00eae;
    font-family: "Montserrat", sans-serif;
  }

  .syntax-hiligheter-sql {
    min-height: 50vh;
  }

  .editor-border {
    min-height: 50vh !important;
  }

  .uuid-code-image-wrapper {
    width: 100%;
    border: solid 1px darkgrey;
    min-height: 270px;
    border-radius: 8px;
    /* margin: 28px; */
  }
  .container-random-number {
    padding: 10% !important;
    margin-top: 5% !important;
  }
  .heading-formatter-text {
    font-size: 18px !important;
  }
  .uuid-display-heading {
    font-size: 22px !important;
    font-weight: bold;
  }
  .uuid-display {
    font-size: 12px !important;
  }
  .uuid-cpy svg{
    width: 16px;
  }
  .uuid-cpy svg:hover {
      width: 16px;
  }
  .uuid-display-heading{
    font-size: 18px !important;
  }
}

/* Styles for tablets (between 768px and 1024px wide) */
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .format-btn {
    width: 100% !important;
  }

  .button-section-2 {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .syntax-hiligheter-sql {
    min-height: 50vh;
  }

  .editor-border {
    min-height: 50vh !important;
  }

  .mobile-res-row {
    margin-bottom: 3% !important;
  }

  .format-btn {
    /* width: 183px; */
    /* width: 100%; */
    height: auto;
    border-radius: 5px;
    font-family: "Montserrat", sans-serif;
    color: #fff;
    background-color: #c00eae;
    font-size: 10px;
    font-weight: 700;
    padding: 7px;
  }
  .qr-code-image-wrapper {
    min-width: 280px;
  }
  .uuid-code-image-wrapper {
    width: 250px !important;
    border: solid 1px darkgrey;
    min-height: 360px;
    border-radius: 8px;
    margin: 28px;
  }
}

/* Styles for desktops (1025px and above) */
@media only screen and (min-width: 1025px) {
  .uuid-code-image-wrapper {
    min-width: 320px !important;
  }
}
