.container {
  padding: 40px;
}

.heading-formatter {
}
.button-container{
  width: 20% !important;
}
.heading-formatter-text {
  font-size: 22px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  color: #c00eae;
}

.heading-paragraph {
  font-size: 17px;
  font-weight: 400;
  margin-top: 10px;
  font-family: Manrope, sans-serif;
  color: #30323e;
}

.card-formatter {
  background-color: white;
  padding: 3%;
}

.copy-paste label {
  font-size: 18px;
  font-weight: 600;
  /* margin-bottom: 10px; */
  font-family: "Montserrat", sans-serif;
  line-height: 44.98px;
}

.text-area-formatter {
  min-height: 200px !important;
  width: 100%;
  padding: 2%;
  color: #000;
  border: 2px solid;
  border-radius: 10px;
  background-color: #fff;
  border-color: #e4e4e4;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.text-area-formatter-new {
  min-height: 500px !important;
  height: 500px;
  width: 100%;
  padding: 2%;
  color: #000;
  border: 2px solid;
  border-radius: 10px;
  background-color: #fff;
  border-color: #e4e4e4;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.text-area-formatter-new-csv-json {
  min-height: 1130px !important;
  width: 100%;
  padding: 2%;
  color: #000;
  border: 2px solid;
  border-radius: 10px;
  background-color: #fff;
  border-color: #e4e4e4;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.input-area-formatter {
  width: 100%;
  padding: 2%;
  color: #000;
  border: 2px solid;
  border-radius: 4px;
  background-color: #fff;
  border-color: #e4e4e4;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.custom-dnd {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.uploadIcon {
  height: 40px;
}

.custom-input-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 24.2px;
  font-family: "Montserrat", sans-serif;
  color: #202020;
}

.browse-file-button {
  width: 100px;
  height: 34px;
  border-radius: 14px;
  color: #202020;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  border: 2px solid #cbd0dc;
  padding: 24.92px, 51.4px, 24.92 51.4;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.browse-file-button:hover {
  background-color: #c00eae;
  color: #fff;
  border: 2px solid #c00eae;
}

.main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.dropzone-container {
  width: 525px;
  height: 225px;
}

.button-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.label-drop-down {
  /* font-size: 13px; */
  font-size: 11px !important;
  line-height: 44.98px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  margin-top: 15px;
}

.button-section-2 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 4%;
}
.button-section-epoch {
  display: flex;
  justify-content: center !important;
  align-items: center;
  padding: 0 !important;
}

.format-btn {
  /* width: 183px; */
  /* width: 100%; */
  height: auto;
  border-radius: 5px;
  font-family: "Montserrat", sans-serif;
  color: #fff;
  background-color: #c00eae;
  font-size: 12px;
  font-weight: 700;
  padding: 7px !important;
}

.format-btn-epoch {
  /* width: 183px; */
  min-width:  25% !important;
  width: 100%;
  height: auto;
  border-radius: 30px !important;
  font-family: "Montserrat", sans-serif;
  color: #fff;
  background-color: #c00eae;
  font-size: 12px;
  font-weight: 700;
  padding: 14px;
}

.formatted-heading {
  color: #c00eae;
  font-size: 18px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  text-align: center;
  /* padding: 4%; */
  /* padding-top: 4%;
    padding-bottom: 4%; */
}
.formatted-heading-epoch {
  color: #c00eae;
  font-size: 18px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  text-align: start;
  padding: 0 !important;
  line-height: 44.98px;
}
.formatted-heading-epoch-Date {
  color: #202020;
  font-size: 15px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  text-align: start;
  padding: 0 !important;
  margin-right: 5px !important;
}
.container-epoch-converted-date {
  display: flex;
  justify-content: start;
  align-items: center;
}
.editor_holder_button {
  display: flex;
  justify-content: end;
  align-items: center;
  /* margin-bottom: 20px; */
  right: 6%;
  top: 14%;
}

.editor_holder_button_bg {
  background-color: #c00eae;
  border-radius: 5px;
  width: 35px;
  height: 35px;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.editor_holder_button button svg {
  width: 31.7px;
  height: 33px;
  color: #fff;
}

.main-content-container {
  margin-top: 4%;
}

.main-heading {
  font-size: 27px;
  font-weight: 700;
  line-height: 56.22px;
  color: #c00eae;
  font-family: "Montserrat", sans-serif;
}

.main-heading-paragraph {
  font-family: Manrope, sans-serif;
  font-size: 17px;
  line-height: 29.23px;
  color: #30323e;
}

.sub-heading {
  margin-bottom: 20px;
}

.sub-list-ul {
  padding-left: 4%;
}

.sub-list-ul li {
  list-style-type: square;
  font-size: 17px;
  font-family: Manrope, sans-serif;
  font-weight: 400;
  color: #30323e;
}

.preview-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 1400px) {
  .formatted-heading {
    padding-bottom: 8px;
  }
}

.error-message {
  color: #924040;
  background-color: #fde1e1;
  border-color: #fcd2d2;
  padding-right: 3.75rem;
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0;
  transition: opacity 0.15s linear;
    padding-right: 4%;
}

.closeButton svg {
  font-size: 10px;
  padding: 1.05rem 1.25rem;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  cursor: pointer;
  color: #000;
  box-sizing: content-box;
  width: 2em;
  height: 2em;
}

.epoch-converter-input {
  width: 200px;

  display: block;
  width: 100%;
  padding: 0.47rem 0.75rem;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
}
.epoch-select {
  display: block;
  border: 1px solid #ced4da;
  width: 100%;
  padding: 0.47rem 1.75rem 0.47rem 0.75rem;
  /* padding: .47rem .75rem; */
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
}

.success-message {
  color: #a8bf93;
  background-color: #e0edd3;
  border-color: #74d673;
  padding-right: 3.75rem;
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0;
  transition: opacity 0.15s linear;
    padding-right: 4%;
}

.react-json-editor {
  /* overflow-y: auto; */
  max-height: 200vh;
}

.epoch-time-showing {
  background-color: aliceblue;
  padding: 5px 15px;
  border: rgb(206, 212, 218) solid 1px;
  width: 50%;
  display: inline-block;
}
.epoch-input {
  margin-left: 2px;
}
.editor-border {
  border: 2px solid #e4e4e4 !important;
  border-color: #e4e4e4 !important;
  border-radius: 10px !important;
  min-height: 80% !important;
  padding:3%;
}
.editor-border-new {
  border: 2px solid #e4e4e4 !important;
  border-color: #e4e4e4 !important;
  border-radius: 10px !important;
  /* height: 500px !important; */
  height: auto;
  min-height: 500px !important; 
  /* padding: 3%; */
  /* overflow-y: scroll; */
  overflow: hidden;
    box-sizing: border-box;
}
.editor-border-new-csv-json {
  border: 2px solid #e4e4e4 !important;
  border-color: #e4e4e4 !important;
  border-radius: 10px !important;
  height: 1130px !important;
  padding: 3%;
  overflow-y: scroll;
}
.start-stop{
  color: #c00eae;
  font-size: 20px;
  margin-right: 5px;
}
.start-stop-end {
  color: #c00eae;
  font-size: 20px;
  margin-left: 5px;
}





/* responsive */


/* Styles for mobile devices (up to 768px wide) */
@media only screen and (max-width: 768px) {
  .epoch-button{
    text-align: center !important;
  }

  .epoch-time-showing {
      background-color: aliceblue;
      padding: 5px 15px;
      border: rgb(206, 212, 218) solid 1px;
      width: 100% !important;
      display: inline-block;
    }
  .mobile-responsive-timer{

    display: none;
  }
  .mobile-responsive-timer-show{
    display: block !important;
  }

  .mobile-responsive-col {}

  .button-section-2 {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .format-btn {
    margin-bottom: 20px;
  }

  .button-mob {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
  }

  .mobile-res-row {
    margin-bottom: 5px;
  }

  
    .copy-paste label {
      font-size: 16px;
      line-height: 25.22px !important;
    }

  .custom-input-text {
    font-size: 12px;
  }

  /* .editor-border{
  margin-bottom: 2%;
} */
  .mobile-responsive-col {
    margin-bottom: 10%;
  }

  .mobile-responsive-view-btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .main-heading {
    font-size: 20px !important;
    font-weight: 700;
    line-height: 25.22px;
    color: #c00eae;
    font-family: "Montserrat", sans-serif;
  }

  .syntax-hiligheter-sql {
    min-height: 50vh;
  }

  .editor-border {
    min-height: 80vh !important;
  }

.button-container {
  width: 100% !important;
}
  
    .format-btn {
      /* width: 183px; */
      /* width: 100%; */
      height: auto;
      border-radius: 5px;
      font-family: "Montserrat", sans-serif;
      color: #fff;
      background-color: #c00eae;
      font-size: 10px;
      font-weight: 700;
      padding: 12px;
    }
}
.left-container-padding {
  padding: 2%;
}

/* Styles for tablets (between 768px and 1024px wide) */
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .left-container-padding {
      padding: 3.5%;
    }
  .syntax-hiligheter-sql {
    min-height: 50vh;
  }
     .mobile-responsive-timer-show {
       display: none !important;
     }

  .editor-border {
    min-height: 50vh !important;
  }

  .mobile-res-row {
    margin-bottom: 3% !important;
  }
    .format-btn {
      /* width: 183px; */
      /* width: 100%; */
      height: auto;
      border-radius: 5px;
      font-family: "Montserrat", sans-serif;
      color: #fff;
      background-color: #c00eae;
      font-size: 10px !important;
      font-weight: 700;
      padding: 7px;
    }
    .editor_holder_button{
      margin-bottom: 10px !important;
    }
}

/* Styles for desktops (1025px and above) */
@media only screen and (min-width: 1025px) {



}

.column-2-center {
  display: flex;
  justify-content: center;
  flex-direction: column;
}