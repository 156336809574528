.editor-border-minifier{
    /* min-height: 76% !important; */
    max-height: 300px !important;
    min-height: 300px !important;
    border: 2px solid #e4e4e4 !important;
        border-color: #e4e4e4 !important;
        border-radius: 10px !important;
        
        overflow-y: scroll;
        padding: 3%;
}
.text-area-formatter{
    min-height: 300px !important;
}

/* .editor-borderCss-minify{
    min-height: 72% !important;
} */
 
 .button-section-2{
display: flex;
justify-content: center !important;
align-items: center !important;
 }
 .format-btn-minify{
    width: 50% !important;
    height: auto;
        border-radius: 5px;
        font-family: "Montserrat", sans-serif;
        color: #fff;
        background-color: #c00eae;
        font-size: 12px;
        font-weight: 700;
        padding: 7px !important;
    
 }