.login-bg{
    /* background-color: #c00eae !important; */
    background-color: #fff !important;
    height: 100vh;
}
/* login.css */

.container-login {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: url('./../../../assets/bg/bg-image.png') no-repeat center center fixed;
    /* background-color: #fff; */
    background-size: cover;
}

.login-row-class {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.col-4-login-card {
    /* background: rgba(255, 255, 255, 0.8); */
    background-color: white ;
    /* White background with slight transparency */
    border-radius: 8px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    /* Shadow effect */
    /* padding: 20px; */
    padding-left: 4rem;
    padding-right: 4rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
    border-radius: 1rem;
    max-width: 630px;
    /* Maximum width of the login form */
    width: 100%;
  /* box-shadow: 5px 5px 5px #f472b6 !important; */
}

.login-text {
    text-align: center;
    margin-bottom: 20px;
}

.input-email input,.password-field input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.login-btn {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    border: none;
    color: white;
    border-radius: 4px;
    cursor: pointer;
}

 

.login-main{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.login-logo{
    margin-bottom: 2.25rem;
    /* max-width: 250px; */
    max-width: 200px;
    display: inline;
}

.login-text{
    font-size: 1.5rem;
    margin-bottom: 2.5rem;
    line-height: 1.5;
    text-align: center;
    background: linear-gradient(to right, #c00eae, #30323E);
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
}

.password-field {
    position: relative;
}

.password-field input {
    width: 100%;
    /* Make input full width */
    padding-right: 30px;
    /* Add padding to the right for the toggle button */
}

.toggle-password {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
    width: 4%;
    display: flex;
    justify-content: center;
    align-items: center;
}
/* button:hover{
    background-color: transparent !important;
} */
.eye-button-password{
    color: #c00eae;
}
.login-btn{
    background-color: #c00eae;
    color: white;
}
.login-btn:hover{
    color: white;
    background-color: #c00eae !important;
}

 

 
.password-field input:focus,
.password-field input:focus-visible {
    border-color: #c00eae !important;
}
 
.input-email input:focus,
.input-email input:focus-visible {
    border-color: #c00eae !important;
    outline: none;
}
input[type="text"]:focus,
input[type="text"]:focus-visible,
input[type="password"]:focus,
input[type="password"]:focus-visible {
    border-color: #c00eae !important;
    outline: none;
}

 