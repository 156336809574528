.cs-site-subscribe {
  padding: 25px 0;
}
.cs-site-subscribe__form {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;

  margin: 0 auto;
}

.cs-site-subscribe__title {
  font-size: 2.625rem;
}

.cs-site-subscribe__info-text {
  margin-top: 1rem;
  color: #6c757d;
  font-family: "Manrope", sans-serif;
}

.pk-subscribe-form-block {
  margin-top: 1rem;
}

.pk-subscribe-container {
}
.pk-subscribe-submit {
  margin-top: 0;
  margin-left: 0.5rem;
  width: auto;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  text-decoration: none;
  border: none;
  line-height: 1rem;
  background-color: #c00eae;
  color: white;

  transition: 0.25s;
  box-shadow: none;
  padding: 0.75rem 1.5rem;
  border-radius: 6px;
}
.subscribe-btn {
  /* display: flex;
  justify-content: end;
  align-items: center; */
}
.pk-input-group input {
  border-radius: 6px;
  width: 100%;
  padding: 0.375rem 1rem;
  border: 1px solid #c00eae;
  font-size: 0.75rem;
  font-weight: 600;
  font-family: "Manrope", sans-serif;
  background-color: transparent;
  color: #c00eae;
}
.pk-input-group input:focus {
  border-color: #c00eae;
  outline: none;
}
.pk-privacy{
    margin-top: 10px;
    display: flex;
    justify-content: start;
    align-items: center;
    text-align: center;
  font-family: "Manrope", sans-serif;
  font-size: 0.75rem;
  font-weight: 600;
}
.pk-privacy input{

        margin-top: 0.3em;
        margin-right: 0.5rem;
        height: 1rem;
        width: 1rem;
}

.subscribe-btn {
  display: flex;
  align-items: center; /* Centers items vertically */
}

.button-container {
  display: flex;
  justify-content: space-between; /* Distributes space evenly between items */
  align-items: center; /* Centers items vertically */
  width: 100%; /* Ensure the container takes full width */
}
.spacer {
  flex: 1; /* Takes up available space */
}

.error-message-text{
  color: red;
  font-size: 0.85rem;
}