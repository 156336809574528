article {
  --img-scale: 1.001;
  --title-color: black;
  --link-icon-translate: -20px;
  --link-icon-opacity: 0;
  position: relative;
  border-radius: 16px;
  box-shadow: none;
  background: #fff;
  /* transform-origin: center; */
  /* transition: all 0.4s ease-in-out; */
  overflow: hidden;
}

article a::after {
  position: absolute;
  inset-block: 0;
  inset-inline: 0;
  cursor: pointer;
  content: "";
}

/* basic article elements styling */
article h2 {
  margin: 0 0 18px 0;
  /* font-family: "Bebas Neue", cursive; */
  font-family: "Montserrat", sans-serif;
  font-size: 1.9rem;
  letter-spacing: 0.06em;
  color: var(--title-color);
  /* transition: color 0.3s ease-out; */
}
.blog-title {
  font-family: "Montserrat", sans-serif !important;
  color: #2f323d;
  display: -webkit-box; /* Fallback for older browsers */
  -webkit-box-orient: vertical; /* Sets the box to be vertical */
  -webkit-line-clamp: 2.5; /* Limits the number of lines to 3 */
  overflow: hidden; /* Hides the overflow text */
  text-overflow: ellipsis; /* Adds an ellipsis (...) for overflow text */
  max-height: 4.5em; /* Adjust based on line height, typically line-height * number of lines (1.5em * 3) */
  line-height: 1.5em; /* Adjust the line height */
  margin: 1em 0;
  height: 80px;
}
article:hover .blog-title {
  color: var(--title-color) !important;
}
figure {
  margin: 0;
  padding: 0;
  aspect-ratio: 16 / 9;
  overflow: hidden;
}

article img {
  max-width: 100%;
  /* transform-origin: center; */
  /* transform: scale(var(--img-scale)); */
  /* transition: transform 0.4s ease-in-out; */
}

.article-body {
  padding: 24px;
}

article a {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  color: #28666e;
}

/* article a:focus {
  outline: 1px dotted #28666e;
} */

article a .icon {
  min-width: 24px;
  width: 24px;
  height: 24px;
  margin-left: 5px;
  /* transform: translateX(var(--link-icon-translate)); */
  opacity: var(--link-icon-opacity);
  /* transition: all 0.3s; */
}

/* using the has() relational pseudo selector to update our custom properties */
article:has(:hover, :focus) {
  --img-scale: 1.1;
  --title-color: #c00eae;
  --link-icon-translate: 0;
  --link-icon-opacity: 1;
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px; */
}

/************************ 
Generic layout (demo looks)
**************************/

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  /* padding: 48px 0; */
  font-family: "Figtree", sans-serif;
  font-size: 1.2rem;
  line-height: 1.6rem;
  background-image: linear-gradient(45deg, #7c9885, #b5b682);
  min-height: 100vh;
}

.articles {
  /* display: grid; */
  /* max-width: 1190px ; */
  width: 100% !important;
  overflow: hidden;

  margin-inline: auto;
  padding-inline: 24px;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 24px;
}
.center-article .swiper-wrapper {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 960px) {
  article {
    container: card/inline-size;
  }
  .article-body p {
    display: none;
  }
}

.article-wrapper {
  display: flex;
  flex-direction: column;
  /* border-radius: 16px; */
  overflow: hidden;
  /* transition: all 0.4s ease-in-out; */
  background: #fff;
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px; */
}

.article-wrapper:hover {
  /* transform: scale(1.05); */
}
@container card (min-width: 380px) {
  /* .article-wrapper {
    display: grid;
    grid-template-columns: 100px 1fr;
    gap: 16px;
  } */
  .article-body {
    padding-left: 0;
  }
  figure {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  figure img {
    height: 100%;
    aspect-ratio: 1;
    object-fit: cover;
  }
}

.sr-only:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  color: #67717a;
}
.read-more {
  font-size: 0.75rem;
  font-weight: 600;
}
/* .category-title{
        transition: 0.25s;
        color: #2f323d;
        font-size: 15px;
} */
.category-title {
  /* transition: color 0.25s ease;  */
  color: #2f323d; /* Default color (dark) */
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
}

/* On hover, change the color to a lighter shade */
.category-title:hover {
  color: #b0b0b0; /* Example of a lighter color */
  font-weight: 400;
}

.blog-description {
  display: -webkit-box; /* Fallback for older browsers */
  -webkit-box-orient: vertical; /* Sets the box to be vertical */
  -webkit-line-clamp: 3; /* Limits the number of lines to 3 */
  overflow: hidden; /* Hides the overflow text */
  text-overflow: ellipsis; /* Adds an ellipsis (...) for overflow text */
  max-height: 4.5em; /* Adjust based on line height, typically line-height * number of lines (1.5em * 3) */
  line-height: 1.5em; /* Adjust the line height */
  margin: 1em 0;
  color: #67717a; /* Optional: Adds margin to the description */
  height: 85px;
}
.blog-date {
  font-size: 0.75rem !important;
  font-weight: 600 !important;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  color: #67717a;
}

.arrow-btn {
  color: #c00eae;
}

figure {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

figure img {
  width: 100%;
  height: auto;
  /* transition: transform 0.4s ease-in-out; */
}

.article-body {
  padding: 24px;
}

.article-body h2 {
  margin: 0 0 18px 0;
  font-family: "Montserrat", sans-serif;
  /* font-size: 1.9rem; */
  font-size: 1.2rem;
  letter-spacing: 0.06em;
  color: #2f323d;
}

.article-body p {
  margin: 0 0 1em 0;
  font-family: "Montserrat", sans-serif;
}

.article-body .read-more {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #2f323d;
}

.article-body .read-more .icon {
  min-width: 24px;
  width: 24px;
  height: 24px;
  margin-left: 5px;
  /* transition: transform 0.3s, opacity 0.3s; */
  opacity: 0;
  /* transform: translateX(-20px); */
}

.carousel-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.article-width {
  /* width: 35%; */
  width: 100%;
}

/* Wrapper around Swiper */
.swiper-wrapper {
  /* overflow: hidden; */
  width: 100%;
  height: 100%;
}

.my-swiper {
  /* Ensure Swiper container has full width and no extra margins */
  width: 100%;
}

.article-width {
  /* Ensure the width of each slide is appropriate */
  width: 100%;
}

.center-article .swiper-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
