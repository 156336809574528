.container {
  padding: 40px;
}

.heading-formatter {
}

.heading-formatter-text {
  font-size: 22px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  color: #c00eae;
}

.heading-paragraph {
  font-size: 17px;
  font-weight: 400;
  margin-top: 10px;
  font-family: "Poppins", sans-serif;
  color: #30323e;
}

.card-formatter {
  background-color: white;
  padding: 3%;
}

.copy-paste label {
  font-size: 18px;
  font-weight: 600;
  /* margin-bottom: 10px; */
  font-family: "Montserrat", sans-serif;
  line-height: 44.98px;
}

.text-area-formatter {
  /* min-height: 200px !important; */
  width: 100%;
  padding: 2%;
  color: #000;
  border: 2px solid;
  border-radius: 10px;
  background-color: #fff;
  border-color: #e4e4e4;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.input-area-formatter {
  width: 100%;
  padding: 2%;
  color: #000;
  border: 2px solid;
  border-radius: 4px;
  background-color: #fff;
  border-color: #e4e4e4;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.custom-dnd {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.uploadIcon {
  height: 40px;
}

.custom-input-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 24.2px;
  font-family: "Montserrat", sans-serif;
  color: #202020;
}

.browse-file-button {
  width: 100px;
  height: 34px;
  border-radius: 14px;
  color: #202020;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  border: 2px solid #cbd0dc;
  padding: 24.92px, 51.4px, 24.92 51.4;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.browse-file-button:hover {
  background-color: #c00eae;
  color: #fff;
  border: 2px solid #c00eae;
}

.main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.dropzone-container {
  width: 525px;
  height: 225px;
}

.button-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.label-drop-down {
  font-size: 13px;
  line-height: 44.98px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  margin-top: 15px;
}

.button-section-2 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* padding-top: 2%; */
  /* margin-top: 2%; */
}

.button-section-epoch {
  display: flex;
  justify-content: center !important;
  align-items: center;
  padding-top: 4%;
}

.format-btn {
  /* width: 183px; */
  /* width: 100%; */
  height: auto;
  border-radius: 5px;
  font-family: "Montserrat", sans-serif;
  color: #fff;
  background-color: #c00eae;
  font-size: 12px;
  font-weight: 700;
  padding: 14px;
}

.format-btn-difference {
  /* width: 183px; */
  width: 30%;
  height: auto;
  border-radius: 25px !important;
  font-family: "Montserrat", sans-serif;
  color: #fff;
  background-color: #c00eae;
  font-size: 12px;
  font-weight: 700;
  padding: 14px;
}

.difference-checker {
  display: flex;
  justify-content: center;
  align-items: center;
}

.formatted-heading {
  color: #c00eae;
  font-size: 18px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  text-align: center;
  /* padding: 4%; */
}

.formatted-heading-epoch {
  color: #c00eae;
  font-size: 18px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  text-align: start;
  padding: 2%;
  line-height: 27.98px !important;
}

.editor_holder_button {
  display: flex;
  justify-content: end;
  align-items: center;
  /* margin-bottom: 20px; */
  right: 6%;
  top: 14%;
}

.editor_holder_button_bg {
  background-color: #c00eae;
  border-radius: 5px;
  width: 35px;
  height: 35px;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.editor_holder_button button svg {
  width: 31.7px;
  height: 33px;
  color: #fff;
}

.main-content-container {
  margin-top: 4%;
}

.main-heading {
  font-size: 27px;
  font-weight: 700;
  line-height: 56.22px;
  color: #c00eae;
  font-family: "Montserrat", sans-serif;
}

.main-heading-paragraph {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  line-height: 29.23px;
  color: #30323e;
}

.sub-heading {
  margin-bottom: 20px;
}

.sub-list-ul {
  padding-left: 4%;
}

.sub-list-ul li {
  list-style-type: square;
  font-size: 17px;
  font-family: Manrope, sans-serif;
  font-weight: 400;
  color: #30323e;
}

.preview-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 1400px) {
  .formatted-heading {
    padding-bottom: 8px;
  }
}

.error-message {
  color: #924040;
  background-color: #fde1e1;
  border-color: #fcd2d2;
  padding-right: 3.75rem;
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0;
  transition: opacity 0.15s linear;
  padding-right: 4%;
}

.closeButton svg {
  font-size: 10px;
  padding: 1.05rem 1.25rem;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  cursor: pointer;
  color: #000;
  box-sizing: content-box;
  width: 2em;
  height: 2em;
}

.epoch-converter-input {
  width: 200px;

  display: block;
  width: 100%;
  padding: 0.47rem 0.75rem;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
}

.epoch-select {
  display: block;
  border: 1px solid #ced4da;
  width: 100%;
  padding: 0.47rem 1.75rem 0.47rem 0.75rem;
  /* padding: .47rem .75rem; */
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
}

.qr-code-image-wrapper {
  width: 100%;
  border: solid 1px darkgrey;
  min-height: 360px;
  border-radius: 8px;
  /* margin: 28px; */
}

.uuid-code-image-wrapper {
  /* width: 300px !important; */
  width: 50% !important;
  border: solid 1px darkgrey;
  min-height: 360px;
  border-radius: 8px;
  margin: 28px;
}

.qr-code-image-wrapper img {
  vertical-align: middle;
}

.uuid-display {
  font-weight: 600;
}

.success-message {
  color: #a8bf93;
  background-color: #e0edd3;
  border-color: #74d673;
  padding-right: 3.75rem;
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0;
  transition: opacity 0.15s linear;
  padding-right: 4%;
}

.social-media-button svg {
  /* color: #c00eae; */
  cursor: pointer;
}

/* .social-media-button svg:hover{
    color: #ffffff;
 } */

.social-media-text {
  font-size: 21px;
  font-weight: 500;
}

/* Stop watch */
stopwatch {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 5px solid;
  border-radius: 50px;
  background-color: white;
  padding: 30px;
}

.main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.display {
  /* font-size: 5rem; */
padding-top: 10%;
  font-size: 4.8rem;
  font-family: monospace;
  font-weight: bold;
  color: hsl(0, 0%, 30%);
  /* text-shadow: 2px 2px 2px hsla(0, 0%, 0%, 0.75); */
  /* margin-bottom: 25px; */
}

.controls button {
  font-size: 20px;
  font-weight: bold;
  padding: 10px 20px;
  margin: 5px;
  min-width: 129px !important;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  color: white;
  transition: background-color 0.5s ease;
}

.start-button {
  /* background-color: hsl(115, 100%, 40%); */
  background-color: #c00eae;
}

.back-button {
  background-color: #c00eae;
  display: flex;
  justify-content: center;
  align-items: center;
}

.start-button:hover {
  /* background-color: hsl(115, 100%, 35%); */
}

.stop-button {
  /* background-color: hsl(10, 90%, 50%); */
  background-color: #c00eae;
}

.stop-button:hover {
  /* background-color: hsl(10, 90%, 45%); */
  background-color: #c00eae;
}

.reset-button {
  /* background-color: hsl(205, 90%, 60%); */
  background-color: #c00eae;
}

.reset-button:hover {
  /* background-color: hsl(205, 90%, 55%); */
  background-color: #c00eae;
}

.container-stop-watch {
  display: flex;
  justify-content: center;
  align-items: center;
}

.stopwatch .milliseconds {
  font-size: 0.5em;
  display: block;

  /* margin-top: 8rem !important; */
  margin-top: 8rem !important;
}

.button-number {
  /* position: absolute; */
  display: block;
  background-color: rgb(255, 255, 255);
  width: 60px;
  height: 60px;
  cursor: pointer;
  background-color: #c00eae;
  margin: auto;
  border: 1px solid #ccc;
  color: #fff;
  border-radius: 10%;
  /* margin-right: 35px; */
  font-size: 20px;
  font-weight: 600;
  padding: 10px 20px;
}

.button-number:hover {
  background: linear-gradient(135deg, #c00eae, #ff00ff);
}

.button-number-2 {
  /* position: absolute; */
  display: block;
  background-color: rgb(255, 255, 255);
  width: 100px;
  color: #fff;
  height: 60px;
  cursor: pointer;
  background-color: #c00eae;
  margin: auto;
  border: 1px solid #ccc;
  border-radius: 10%;
  /* margin-right: 35px; */
  font-size: 20px;
  font-weight: 600;
  padding: 10px 20px;
  margin-bottom: 0.5rem;
}

.button-number-2:hover {
  background: linear-gradient(135deg, #c00eae, #ff00ff);
}

.main-number-container-right {
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  text-align: start;
  margin-left: 6px;
}

.buttons-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.counter-container {
  border: 4px solid #000;
  border-radius: 25px;
  padding-left: 5%;
  padding-right: 5%;
  margin-bottom: 25px;
}

.inner-container-container {
  margin-bottom: 20px;
}

.timer-stopped {
  background-color: aliceblue;
  /* Initial background color */
  animation: blink-animation 1s infinite alternate;
}

@keyframes blink-animation {
  0% {
    background-color: aliceblue;
    /* Start with blue */
    color: inherit;
    /* Use the default color */
  }

  50% {
    background-color: #f9757a;
    /* Change to red halfway */
    color: inherit;
    /* Use the default color */
  }

  100% {
    background-color: aliceblue;
    /* Change back to blue */
    color: inherit;
    /* Use the default color */
  }
}

@media only screen and (max-width: 768px) {
  .stopwatch .milliseconds {
    font-size: 0.5em;
    display: block;

    margin-top: 2rem !important;
  }
  .display{
    font-size: 4rem !important;
  }
  .counter-container {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .inner-container-container {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .display {
    /* font-size: 3.5rem !important; */
    font-size: 2.8rem !important;
    padding-top: 0 !important;
    /* padding-left: 5% !important;
    padding-right: 5% !important; */
  }

  .qr-code-image-wrapper {
    width: 100% !important;
    min-height: 270px !important;
    /* margin: -2px; */
    padding: 5%;
  }

  .format-btn {
    width: 100% !important;
  }

  .mobile-responsive-col {
  }

  .button-section-2 {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .format-btn {
    margin-bottom: 20px;
  }

  .button-mob {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
  }

  .mobile-res-row {
    margin-bottom: 5px;
  }

  .copy-paste label {
    font-size: 16px;
    line-height: 25.22px !important;
  }

  .custom-input-text {
    font-size: 12px;
  }

  .mobile-responsive-col {
    margin-bottom: 10%;
  }

  .mobile-responsive-view-btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .main-heading {
    font-size: 20px !important;
    font-weight: 700;
    line-height: 25.22px;
    color: #c00eae;
    font-family: "Montserrat", sans-serif;
  }

  .syntax-hiligheter-sql {
    min-height: 50vh;
  }

  .editor-border {
    min-height: 50vh !important;
  }

  .uuid-code-image-wrapper {
    width: 100% !important;
    border: solid 1px darkgrey;
    min-height: 270px;
    border-radius: 8px;
    /* margin: 28px; */
  }

  .controls button {
    font-size: 18px !important;
  }

  .web-view-counter {
    display: none;
  }

  .button-third-row {
    width: 123px !important;
  }

  .button-number-2 {
    width: 250px !important;
    margin-top: 8px;
    font-size: 15px !important;
  }

  .button-number-2-clear {
    border-radius: 3% !important;
  }

  .date-time-container {
    padding: 10% !important;
    margin-top: 6% !important;
  }

  .mobile-time-picker .input {
    font-size: 1.625em !important;
  }

  .mobile-date-picker .MuiOutlinedInput-input {
    background-color: #ececec !important;
    font-size: 1.625em !important;
    cursor: pointer !important;
  }

  .callender-icon {
    right: 4% !important;
    font-size: 1.5em !important;
    bottom: 36% !important;
  }

  .arrow-icon-two {
    position: absolute;
    right: 12% !important;
    font-size: 2.5em;
    bottom: 26% !important;
    cursor: pointer !important;
  }
  .callender-icon-two {
    position: absolute;
    right: 7% !important;
    font-size: 1.5em !important;
    bottom: 34% !important;
    cursor: pointer !important;
  }
}

/* Styles for tablets (between 768px and 1024px wide) */
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .stopwatch .milliseconds {
    font-size: 0.5em;
    display: block;

    /* margin-top: 8rem !important; */
    margin-top: 3rem !important;
  }

  .arrow-icon {
    right: 3% !important;
  }
  .arrow-icon-two {
    right: 3% !important;
  }
  .callender-icon {
    right: 4% !important;
    font-size: 2em !important;
    bottom: 32% !important;
  }
  .callender-icon-two {
    right: 4% !important;
    font-size: 2em !important;
    bottom: 32% !important;
  }
  .syntax-hiligheter-sql {
    min-height: 50vh;
  }

  .mobile-date-picker .MuiOutlinedInput-input {
    background-color: #ececec !important;
    font-size: 2em !important;
    cursor: pointer !important;
  }

  .mobile-time-picker .input {
    font-size: 2.625em !important;
  }

  .mobile-view {
    display: none;
  }

  .mobile-view-counter {
    display: none;
  }

  .editor-border {
    min-height: 50vh !important;
  }

  .mobile-res-row {
    margin-bottom: 3% !important;
  }

  .container-stop-watch {
    min-height: 130vh;
  }

  .format-btn {
    /* width: 183px; */
    /* width: 100%; */
    height: auto;
    border-radius: 5px;
    font-family: "Montserrat", sans-serif;
    color: #fff;
    background-color: #c00eae;
    font-size: 10px;
    font-weight: 700;
    padding: 7px;
  }

  .qr-code-image-wrapper {
    min-width: 280px;
  }

  .container-stop-watch {
    min-height: auto;
  }

  .uuid-code-image-wrapper {
    width: 250px !important;
    border: solid 1px darkgrey;
    min-height: 360px;
    border-radius: 8px;
    margin: 28px;
  }
}

/* Styles for desktops (1025px and above) */
@media only screen and (min-width: 1025px) {
  .container-stop-watch {
    min-height: 130vh;
  }

  .mobile-view {
    display: none;
  }

  .mobile-view-counter {
    display: none;
  }

  .counter-container {
    width: 900px !important;
    height: 550px;
    /* padding-right:30% !important; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0%;
  }

  .display {
    font-size: 11rem !important;
  }
}

.border-time-zone {
  /* border: 2px solid #ECECEC; */
  /* padding: 2%; */
  /* border-radius: 5px; */
}

.time-zone-select {
  background-color: #c00eae;
  padding: 2%;
}

.time-zone-select .css-13cymwt-control {
  background-color: #c00eae !important;
  color: white !important;
}
.time-zone-select .css-hlgwow {
  background-color: #c00eae !important;
  color: white !important;
}

.time-zone-select .css-1fdsijx-ValueContainer {
  background-color: #c00eae !important;
  color: white !important;
}

.time-zone-select .css-1xc3v61-indicatorContainer svg {
  color: #c00eae !important;
}

.mobile-time-picker .input,
.MuiOutlinedInput-notchedOutline {
  font-family: monospace;
  font-size: 4.625em;
  font-weight: bold !important;
  color: hsl(0, 0%, 30%) !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin-left: auto !important;
  cursor: pointer !important;
  margin-right: auto !important;
  /* padding-left: 20% !important; */
  text-align: center !important;
}

.mobile-time-picker .MuiInputBase-input {
  font-family: monospace;
  font-size: 4.625em;
  font-weight: bold !important;
  color: hsl(0, 0%, 30%) !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin-left: auto !important;
  cursor: pointer !important;
  margin-right: auto !important;
  /* padding-left: 20% !important; */
  text-align: center !important;
}

.css-11a8txn-MuiStack-root {
  border: none !important;
  /* border-color: #c00eae !important; */
}
.css-igs3ac {
  border: none !important;
  /* border-color: #c00eae !important; */
}

.time-picker-container fieldset {
  border: none !important;
}
.time-picker-container fieldset {
  border: none !important;
}
.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none !important;
}
.mobile-date-picker fieldset {
  border: none !important;
}

.mobile-date-picker .MuiOutlinedInput-input {
  background-color: #ececec !important;
  font-size: 2.625em;
  cursor: pointer !important;
}
.mobile-date-picker input {
  background-color: #ececec !important;
  font-size: 2.625em;
  cursor: pointer !important;
}

/* .mobile-date-picker .input  {
 
    text-align: center !important;
    cursor: pointer !important;
} */
.mobile-date-picker input {
  text-align: center !important;
  cursor: pointer !important;
}

.mobile-date-picker {
  position: relative !important;
  cursor: pointer !important;
}

.time-picker-container {
  position: relative !important;
  width: 100% !important;
}

.time-picker-container {
  width: 100% !important;
  display: flex;
  align-items: center;
}

.mobile-time-picker {
  position: relative !important;
}

.arrow-icon {
  position: absolute;
  right: 7%;
  font-size: 2.5em;
  cursor: pointer !important;
}

.arrow-icon-two {
  position: absolute;
  right: 7%;
  font-size: 2.5em;
  bottom: 48%;
  cursor: pointer !important;
}

.callender-icon {
  position: absolute;
  right: 12%;
  font-size: 2.5em;
  bottom: 28%;
  cursor: pointer !important;
}

.callender-icon-two {
  position: absolute;
  right: 12%;
  font-size: 2.5em;
  bottom: 28%;
  cursor: pointer !important;
}

.mobile-time-picker-two {
  position: relative !important;
}

.mobile-time-picker-three {
  cursor: pointer !important;
  position: relative !important;
  width: 100% !important;
  background-color: #ececec !important;
}

.container-date-time {
  width: 100% !important;
  background-color: #ececec !important;
}
