pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal;
      /* overflow-y: scroll; */
}

code {
    font-size: 87.5%;
    color: #e83e8c;
    word-wrap: break-word;
}
 .text-font{
    font-size: 15px;
 }