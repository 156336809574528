.mb-10 {
  margin-bottom: 10px;
}

.mb-30 {
  margin-bottom: 30px;
}

.dashboard-sub-icons svg {
  width: 30px;
  height: 30px;
  margin-bottom: 15px;
}

.tools-heading-text {
  /* font-size: 20px; */
  font-size: 18px;

  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  color: #000;
}

.tools-description {
  text-align: justify;
}

.dashboard-tools-card {
  padding: 20px !important;
  border-radius: 20px;
}

.card {
  border: 2px solid #e4e4e4;
  border-radius: 20px;
  padding: 30px !important;
  min-height: 180px;
  /* transition: background-color 0.5s ease; */
}
.dashboard-card:hover {
  background: linear-gradient(135deg, #c00eae, #ff6f61);

  color: #fff !important;
}
/* .heading-formatter-text{
   font-family: 'Ethnocentric', sans-serif !important;
} */
.tools-icon-heading {
  display: flex;
}
.dashboard-card:hover .tools-heading-text {
  color: #fff !important;
}
.dashboard-card {
  display: flex;
  justify-content: center;
}
.dashboard-tools-card {
  /* box-shadow: 5px 5px 5px #f472b6 !important; */
  box-shadow: 5px 5px 5px #e4e4e4 !important;
}
.tools-btn {
  color: #fff;
  background-color: #836e81;
  font-size: 14px;
  font-weight: 700;
  padding: 10px;
  border-radius: 10px;
  margin-top: 10px;
}

@media only screen and (max-width: 768px) {
  .tools-heading-text {
    /* font-size: 20px; */
    font-size: 13px !important;

    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    color: #000;
  }

  .card {
    border: 2px solid #e4e4e4;
    border-radius: 20px;
    padding: 30px !important;
    min-height: 140px;
  }
}

/* .background1{
  background-color: #E5F9FF !important;
  
}
.background2{
  background-color:#FFEDE5 !important;
}
.background3{
  background-color: #EAE5FF !important;
}
.background4{
  background-color:#e6f7ef !important;
}
 
.background5{
  background-color:#d1f7fd !important;
}
 
.background6{
  background-color:#e9ecff !important;
} */
