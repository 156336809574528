.cs-footer__col .cs-col-left{

    padding-right: 40px;
        max-width: 280px;
}

.cs-footer__copyright{

    font-size: 0.75rem;
    font-weight: 600;
    color: #67717a;
}
.cs-footer__item{
    background-color: #fff;
    border-radius: 12px;
    padding: 2.5rem 2rem;
}
.cs-container_new{


    padding: 40px !important;
}