.grid-container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    /* Adjust spacing between items */
}

.grid-item {
    flex: 1 1 calc(33.333% - 16px);
    /* 1/3 width minus gap */
    box-sizing: border-box;
    /* background: #f0f0f0;
    padding: 16px; */
}