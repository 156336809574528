.blog-container {
  min-height: 100vh;
}

.category-container {
  display: inline-flex;
}
.category-aviator {
  flex: none;
  width: 3rem;
  height: 3rem;
  background-color: #c00eae !important;
  background-size: 100% 6rem;
  background-position: center top;
  color: white;
  transition: 0.25s;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.category-title {
  color: #2f323d;
  transition: color 0.25s;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  /* padding-left: 0.5rem; */
  font-size: 0.75rem;
  font-weight: 500;
}
.category-title_details {
  color: #2f323d;
  transition: color 0.25s;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding-left: 0.5rem !important;
  font-size: 0.75rem;
  font-weight: 500;
}
.category-title:hover {
  color: #2f323d !important;
  font-weight: 500;
}
.blog-timing {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.75rem;
  margin-top: 2rem;
  text-align: center;
  margin-bottom: 3rem;
}

.social-media {
  display: flex;
  flex-direction: column;
  gap: 2rem; /* This sets the gap between the icons */
  align-items: start; /* Center the icons horizontally */
}
.facebook {
  color: #2f323d;
  cursor: pointer;
}
.facebookTwo {
  color: #316ff6;
  cursor: pointer;
}
.facebook:hover {
  color: #316ff6;
}
.twitter {
  color: #2f323d;
  cursor: pointer;
}
.twitterTwo {
  color: #1da1f2;
  cursor: pointer;
}
.twitter:hover {
  color: #1da1f2;
}
.pintrest {
  color: #2f323d;
  cursor: pointer;
}
.pintrestTwo {
  color: #e60023;
  cursor: pointer;
}
.pintrest:hover {
  color: #e60023;
}

.top-on-the-weeks {
  padding: 1.5rem;
  background-color: #fff;
}
.cs-sidebar__inner {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  height: 100%;
  column-gap: 40px;
  background-color: #fff;
  position: sticky;
  transition: 0.4s;
  background-color: #fff;
  /* padding: 1.5rem; */
}

.wp-block-heading {
}

.is-style-cs-heading-sidebar {
  margin-top: -0.625rem;
  margin-bottom: 1rem !important;
  /* padding-bottom: 0.875rem; */
  /* border-bottom: 1px solid #e9ecef; */
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.75rem;
}
.sc-main{

         position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 20px; 

}
.widget {
      position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 20px; 
  padding: 1.5rem;
  background-color: #ffffff;
  transform: translate3d(0, 0, 0);
  /* box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05); */
  overflow: hidden;
  border-radius: 12px;
  position: sticky;
  transition: 0.4s;
  width: 100%;
}

.cs-sidebar__inner {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  height: 100%;
}

.top-on-the-teams-inside{
    position: relative;
        border-bottom: 1px solid #dee1e6;
    border-radius: 0;
}

/* .cs-entry__outer{
        background-color: initial;
    border-radius: initial;
    box-shadow: initial;
        transition: 0.25s;
    overflow: hidden;
} */
 .cs-entry__outer {
  display: flex;
  align-items: center;
}


.cs-entry__inner{

    position: absolute;
    top: 0;
    left: 0;
    width: 3rem;
    height: 3rem;
    border-radius: 12px;
    transform: translate3d(0, 0, 0);
    overflow: hidden;
}
.cs-entry__thumbnail{
    position: absolute;
    top: 0;
    left: 0;
    width: 3rem;
    height: 3rem;
    border-radius: 12px;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    overflow: hidden;
    /* background-color: #e60023; */
}
.cs-overlay-background{
        display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    position: relative;
        /* opacity: 0; */
    
}
.cs-posts-area__main{
    display: grid;
    row-gap: 10px !important;
    column-gap: 40px;
 

}


/* new css */
.cs-entry__thumbnail {
  position: relative;
  width: 3rem;
  height: 3rem;
  border-radius: 12px;
  overflow: hidden;
}

.thumbnail-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}

.cs-overlay-background {
  position: relative;
  width: 100%;
  height: 100%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: #b3cec9; */
  background-color: #dee1e6;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease, color 0.3s ease;
  border-radius: 12px;
}

.cs-entry__outer:hover .left-image .overlay {
  background-color: rgba(179, 206, 201, 0.5); /* Glass type background on hover */
}

.overlay-text {
  color: black;
  font-size: 24px;
}

.cs-entry__outer:hover .left-image .overlay-text {
  color: white;
}

.top-on-the-teams-inside-sc::before {
  content: "";
  position: absolute;
  border-top: 1px solid #dee1e6;
  top: 19px;
  left: 0;
  width: 100%;
  height: 1px;
}

.cs-text-right {
  font-size: 0.85rem !important;
  max-width: 677px;
  text-transform: capitalize;
}

.social-media {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.cs-text-right:hover a{
    color: #c00eae;

}

/* mid  blog content  */

.cs-entry__content-wrap{
        display: flex;
    -webkit-box-pack: center;
    justify-content: start;
    flex-wrap: wrap;
}

.sc-mid-title{
    font-size: 2.6rem;
    margin-top: 0;
    margin-bottom: 0;
    /* word-break:break-all; */
    font-weight: 400;
    line-height: 1.14;

}

.cs-entry__post-meta{
        margin-top: 1.25rem;
        font-family: "Manrope", sans-serif;
        color: #67717a;
        display: flex;
        justify-content: start;
        align-items: start;
}
.cs-meta-date{
        /* display: flex;
    -webkit-box-align: center;
    align-items: center; */
     font-family: "Manrope", sans-serif;
     font-weight: 600;
     font-size: 0.75rem;
     
}

.cs-entry__post-media{
        margin-top: 3rem;
}
.post-media img{
    border-radius: 12px;
    background-color: #788877;
    width: 100%;
    object-fit: cover;
    object-position: center;
}

.cs-entry__content-container{
    max-width: 677px;
}
.cs-entry__header{
        margin-bottom: 2.5rem;
}
aside{
    position: sticky
}

.cs-entry__tags{
    width: 100%;
        margin-top: 4rem;
}
.cs-entry__tags ul{
     font-family: "Manrope", sans-serif;
     font-size: 0.875rem;
     font-weight: 600;
         display: flex;
    flex-wrap: wrap;
    -webkit-box-align: start;
    align-items: start;
    margin: 0;
    padding: 0;
    list-style: none;
    -webkit-box-pack: center;
    justify-content: center;
    text-transform: none;
    

}

.cs-entry__tags ul > li {
    margin-right: 1rem;
    margin-bottom: 1rem;
}
.cs-entry__tags ul > li:hover {
    margin-right: 1rem;
    margin-bottom: 1rem;
}
.sde-tags{

    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 0.5rem 1rem !important;
    color: #2f323d;
    background-color: #fff;
    border-radius: 6px;
    line-height: 1;
    -webkit-transition: 0.25s;
    transition: 0.25s;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05);
}
.sde-tags:hover{
        color: #fff;
    background-color:#c00eae;
}


.cs-entry__after-share-buttons{
    background-color: #fff;
    border-radius: 12px;
    padding: 1rem;
        text-align: center;
}
.cs-entry__after-share-buttons-title{

    text-align: center;
}

.pk-share-buttons-items{

    display: flex;
    justify-content: center;
    align-items: center;
        margin-top: 2rem;
}
.social-margin{
    margin-right: 20px;
}


.cs-entry__after-share-buttons-link{

    margin-top: 2rem;
}

.cs-entry__after-share-buttons-input-group{
        position: relative;
   
    display: flex;
}

.cs-entry__after-share-buttons-input-group input{
  background-color: #f5f7f8;
    padding-right: 3rem;
        border: 1px solid transparent;
            padding: 0.375rem 1rem;
            border-radius: 6px;
            font-size: 0.75rem;
}
.cs-entry__after-share-buttons-input-group input:focus{
     outline: none;
    box-shadow: none;
}
.cs-entry__after-share-buttons-text{
      

    display: block;
    width: 100%;
}
 @media only screen and (max-width: 767px) {


  .sde-tags {
    text-align: center;
  }
.cs-entry__after-share-buttons-text{
      

    display: block;
    width: 80%;
}
.pk-privacy{
  text-align: start !important;

}
.cs-site-subscribe__title{
  font-size: 1.65rem !important; 
}
.cs-site-subscribe__info-text{
  font-size: 0.75rem;
}
.sc-mid-title{
  font-size: 1.6rem;
}

 }

.cs-entry__after-share-buttons-copy{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 0;
    width: 3rem;
    background: none;


    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    text-decoration: none;
    border: none;

}

.icon-copy{
    color: #c00eae;
}

.icon-check{
    color: green;
}

 .cs-entry__after-share-buttons-text{
    font-size: 0.75rem;
    color: #67717a;
    font-weight: 600;
    margin-top: 0.5rem;
 }
 .cs-container{
    max-width: 1220px;
 }
 