.container {
  padding: 40px;
}

.container-right {
  /* padding-top: 20px !important; */
  padding-left: 40px !important;
  padding-right: 40px !important;
  padding-bottom: 40px !important;
}

.heading-formatter {}

.heading-formatter-text {
  font-size: 22px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  color: #c00eae;
}

.heading-paragraph {
  font-size: 17px;
  font-weight: 400;
  margin-top: 10px;
  font-family: Manrope, sans-serif;
  color: #30323e;
}

.card-formatter {
  background-color: white;
  padding: 3%;
}

.copy-paste label {
  /* font-size: 18px; */

  font-weight: 600;
  color: #c00eae;
  font-size: 16px !important;
  font-weight: 600;
  font-family: "Montserrat", sans-serif !important;
  /* margin-bottom: 10px; */
  font-family: "Montserrat", sans-serif;
  line-height: 44.98px;
}

.text-area-formatter {
  min-height: 200px !important;
  width: 100%;
  padding: 2%;
  color: #000;
  border: 2px solid;
  border-radius: 10px;
  background-color: #fff;
  border-color: #e4e4e4;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.column-2-center {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.text-area-formatter-new-formatter {
  min-height: 500px !important;
  width: 100%;
  padding: 2%;
  color: #000;
  border: 2px solid;
  border-radius: 10px;
  background-color: #fff;
  border-color: #e4e4e4;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.text-area-formatter-SQL {
  min-height: 690px !important;
  width: 100%;
  padding: 2%;
  color: #000;
  border: 2px solid;
  border-radius: 10px;
  background-color: #fff;
  border-color: #e4e4e4;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.custom-dnd {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.uploadIcon {
  height: 40px;
}

.custom-input-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 24.2px;
  font-family: "Montserrat", sans-serif;
  color: #202020;
}

.browse-file-button {
  width: 100px;
  height: 34px;
  border-radius: 14px;
  color: #202020;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  border: 2px solid #cbd0dc;
  padding: 24.92px, 51.4px, 24.92 51.4;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.browse-file-button:hover {
  background-color: #c00eae;
  color: #fff;
  border: 2px solid #c00eae;
}

.main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.dropzone-container {
  width: 525px;
  height: 225px;
}

.button-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.label-drop-down {
  font-size: 14px;
  line-height: 44.98px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  margin-top: 15px;
}

.button-section-2 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 4%;
}

.format-btn {
  /* width: 183px; */
  width: auto;
  height: 41px;
  border-radius: 19px !important;
  box-shadow: 0 0 3px #c00eae !important;
  font-family: "Montserrat", sans-serif;
  color: #fff;
  background-color: #c00eae;
  font-size: 12px;
  font-weight: 700;
  padding: 14px;
}

.formatted-heading {
  color: #c00eae;
  font-size: 16px !important;
  font-weight: 600;
  font-family: "Montserrat", sans-serif !important;
  text-align: start !important;
  padding-top: 4% !important;
  padding-bottom: 4% !important;
}

.text-area-formatter-new-formatter::placeholder {
  font-size: 14px;
}
.editor_holder_button {
  display: flex;
  justify-content: end;
  align-items: center;
  /* margin-bottom: 20px; */
  right: 6%;
  top: 14%;
}

.editor_holder_button_bg {
  background-color: #c00eae;
  border-radius: 5px;
  width: 35px;
  height: 35px;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.editor_holder_button button svg {
  width: 31.7px;
  height: 33px;
  color: #fff;
}

.main-content-container {
  margin-top: 4%;
}

.main-heading {
  font-size: 20px !important;
  font-weight: 700;
  line-height: 56.22px;
  color: #c00eae;
  font-family: "Montserrat", sans-serif;
}

.main-heading-paragraph {
  font-family: Manrope, sans-serif;
  font-size: 17px;
  line-height: 29.23px;
  color: #30323e;
}

.sub-heading {
  margin-bottom: 20px;
}

.sub-list-ul {
  padding-left: 4%;
}

.sub-list-ul li {
  list-style-type: square;
  font-size: 17px;
  font-family: Manrope, sans-serif;
  font-weight: 400;
  color: #30323e;
}

.preview-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 1400px) {
  .formatted-heading {
    padding-bottom: 8px;
  }
}

.error-message {
  color: #924040;
  background-color: #fde1e1;
  border-color: #fcd2d2;
  padding-right: 3.75rem;
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0;
  transition: opacity 0.15s linear;
  padding-right: 4%;
}

.success-message {
  color: #A8BF93;
  background-color: #E0EDD3;
  border-color: #74D673;
  padding-right: 3.75rem;
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0;
  transition: opacity 0.15s linear;
  padding-right: 4%;
}

.left-container-padding {
  padding: 2%;
}

.closeButton svg {
  font-size: 10px;
  padding: 1.05rem 1.25rem;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  cursor: pointer;
  color: #000;
  box-sizing: content-box;
  width: 2em;
  height: 2em;
}

.react-json-editor {
  /* overflow-y: auto; */
  /* max-height: 200vh; */
}

.success-message {
  color: #a8bf93;
  background-color: #e0edd3;
  border-color: #74d673;
  padding-right: 3.75rem;
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0;
  transition: opacity 0.15s linear;
}

.editor-border {
  border: 2px solid #e4e4e4 !important;
  border-color: #e4e4e4 !important;
  border-radius: 10px !important;
  min-height: 80% !important;
  padding: 3%;
}

.editor-border-new-fomatter {
  border: 2px solid #e4e4e4 !important;
  border-color: #e4e4e4 !important;
  border-radius: 10px !important;
  /* height: 500px !important; */
  height: auto;
  min-height: 500px !important;
  /* padding: 3%; */
    box-sizing: border-box;
  /* overflow-y: scroll; */
}


.editor-border-new-sql {
  border: 2px solid #e4e4e4 !important;
  border-color: #e4e4e4 !important;
  border-radius: 10px !important;
  height: 690px !important;
  padding: 3%;
  overflow-y: scroll;
}

.syntax-hiligheter {

  min-height: 200vh;
  overflow-y: auto;

}

.syntax-hiligheter-sql {

  min-height: 100vh;
  overflow-y: auto;

}

/* Styles for mobile devices (up to 768px wide) */
@media only screen and (max-width: 768px) {

  .mobile-responsive-col {}

  .button-section-2 {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .format-btn {
    margin-bottom: 20px;
  }

  .button-mob {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
  }

  .mobile-res-row {
    margin-bottom: 5px;
  }

 
  .custom-input-text {
    font-size: 12px;
  }

  /* .editor-border{
  margin-bottom: 2%;
} */
  .mobile-responsive-col {
    margin-bottom: 10%;
  }

  .mobile-responsive-view-btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .main-heading {
    font-size: 20px !important;
    font-weight: 700;
    line-height: 25.22px;
    color: #c00eae;
    font-family: "Montserrat", sans-serif;
  }

  .syntax-hiligheter-sql {
    min-height: 50vh;
  }

  .editor-border {
    min-height: 50vh !important;
  }
}

/* Styles for tablets (between 768px and 1024px wide) */
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .left-container-padding {
    padding: 3.5%;
  }

  .syntax-hiligheter-sql {
    min-height: 50vh;
  }

  .editor-border {
    min-height: 50vh !important;
  }

  .mobile-res-row {
    margin-bottom: 3% !important;
  }

  .format-btn {
    /* width: 183px; */
    /* width: 100%; */
    height: auto;
    border-radius: 5px;
    font-family: "Montserrat", sans-serif;
    color: #fff;
    background-color: #c00eae;
    font-size: 10px;
    font-weight: 700;
    padding: 7px;
  }
}

/* Styles for desktops (1025px and above) */
@media only screen and (min-width: 1025px) {

  .format-btn {
    width: 183px !important;
  }

}



/* ai button  */


.btn-hover {
  width: 130px;
  font-size: 17px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  /* margin: 20px; */
  /* height: 50px; */
  height: 35px;
  text-align: center;
  border: none;
  background-size: 300% 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50px;
  moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.btn-hover:hover {
  background-position: 100% 0;
  moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.btn-hover:focus {
  outline: none;
}

.btn-hover.color-11 {
  background-image: linear-gradient(to right, #b119a8, #cf2fbd, #e859d7, #f079f1);
  box-shadow: 0 5px 15px rgba(177, 25, 168, .4);

  /* background-image: linear-gradient(to right, #eb3941, #f15e64, #e14e53, #e2373f);  box-shadow: 0 5px 15px rgba(242, 97, 103, .4); */
}


.gen-ai-button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}