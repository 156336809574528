/* src/components/Loader.css */
.loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Full viewport height */
}

.loader {
  border: 8px solid #f3f3f3; /* Light grey background */
  border-top: 8px solid #c00eae; /* Blue spinner */
  border-radius: 50%;
  width: 60px; /* Size of the spinner */
  height: 60px;
  animation: spin 1s linear infinite;
  margin-bottom: 20px; /* Space between the loader and the logo */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.logo {
  width: 100px; /* Adjust based on your logo size */
  height: auto;
}
