.sidebar-container {
  /* width: 300px; */
  width: 300px;
  height: auto;
  background: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  bottom: 0;
  position: fixed;
  z-index: 50;
  border-inline-end-width: 2px;
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
  top: 0;
}
.sidebar-container-mobile_responsive {
  /* width: 300px; */
  width: 300px;
  height: auto;
  background: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  bottom: 0;
  position: fixed;
  top: 6.5%;
  z-index: 1;
  border-inline-end-width: 2px;
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
  /* top: 0; */
}

.sidebar-container.open {
  /* animation-name: slideOutRight; */
  /* Animation name for opening */
  /* animation-duration: 0.3s; */

  /* animation-timing-function: ease-out; */
}

.sidebar-container.closed {
  animation-name: slideInRight;
  /* Animation name for closing */

  animation-duration: 0.2s;
  /* Animation duration */
  animation-timing-function: ease-in-out;
  /* Easing function */
}

@keyframes slideInRight {
  from {
    left: 0;
    /* Start off screen */
  }

  to {
    left: -300px;
    /* Slide in to the left */
  }
}

@keyframes slideOutRight {
  from {
    left: -300px;
    /* Start on screen */
  }

  to {
    left: 0;
    /* Slide out to the right */
  }
}

.sidebar-container-inside {
  width: 300px;
  height: 90px;
  display: flex;
  padding: 0px 20px;
  /* margin-bottom: 40px; */
  justify-content: center;
  align-items: center;
  /* margin-top: 4%; */
}

.sidebar-logo-container {
  width: 75%;
  height: auto;
  /* background-color: beige; */
  /* margin-top: 26px; */
  /* margin-left: 90px; */
  /* margin-left: 25px; */
  background-repeat: no-repeat;
  position: static;
  z-index: 40;
}

.logo-img {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-img-mobile {
  /* display: flex;
  justify-content: center;
  align-items: center; */
  width: auto;
  padding: 0 1.5rem;
  text-align: center;
  height: 25px;
}

.hamburger-sidebar {
  width: 100%;
  height: 40px;

  /* margin-top: 20px; */
  /* margin-left: 43px; */
  color: #202020;
  cursor: pointer;
}

.sidebar-heading-main {
  width: 131px;
  height: 27px;
  margin-left: 20px;
  /* margin-bottom: 20px; */
  font-weight: 700;
  font-size: 0.9375rem;
  /* margin-top: 13px;
  margin-left: 108px; */
  text-align: start;
  /* margin-left: auto;
  margin-right: auto; */
  text-align: center;
}

.sub-menu-main {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
  margin-bottom: 8px;
  padding: 8px !important;

  height: auto;
}

.main-container-sub-menu {
  padding: 2% 4%;
}

.sub-menu-main.active:hover {
  border-radius: 0.5rem 0.5rem 0 0;
}

.sub-menu-main:hover {
  box-sizing: border-box;
  opacity: 1;
  border: 0;

  padding: 8px !important;

  /* transition: all 0.5s ease; */
  background-color: #f6eeff;
  color: #c00eae;
  /* display: block; */
  /* position: relative; */
  font-size: 8px;
  font-weight: 500;
  line-height: 1.5rem;
  width: 100%;
  border-radius: 0.5rem;
  overflow: hidden;
  /* margin: 2px 0px; */
}

.sub-menu-main_links {
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  /* padding-left: 17%; */
  padding-left: 17%;
  margin-top: -10px;
}

.sub-menu-main_links.active {
  /* padding: 0px 0px 12px 0px; */
  padding-bottom: 12px;
}

.submenu-container {
  width: 149px;
  height: 41px;
  display: flex;
  /* justify-content: space-evenly; */
  align-items: center;
}

.sub-menu-main.active {
  /* border: 1px solid #e4e4e4 !important;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5) !important; */
  border: 0;
  /* height: auto; */
  box-sizing: border-box;
  background-color: rgb(246, 238, 255);
  /* border-radius: 0.5rem !important; */
  border-radius: 0.5rem 0.5rem 0 0;
}

.sub-menu-main.active .submenu-container svg {
  color: #c00eae;
}

.sub-menu-main.active .sub-menu-heading_main {
  color: #c00eae;
}

.sub-menu-main.active .side-bar-nav-icons svg {
  color: #c00eae;
}

.sub-menu-main_links.active {
  background-color: #fafafa !important;
  border-radius: 0 0 0.5rem 0.5rem;
}

.sub-menu-icon {
  width: 26px;
  height: 26px;
}

.sub-menu-heading {
  font-weight: 500 !important;
  font-size: 15px !important;
  line-height: 41.23px;
}

.sub-menu-heading_main {
  font-weight: 600 !important;
  /* font-size: 20px !important; */
  font-size: 1rem !important;
  line-height: 1.875rem;
  /* line-height: 41.23px; */
  margin-left: 15%;
}

.icon {
  margin-left: 5%;
  padding: 8px;
}

.icon svg {
  width: 100%;
  /* height: auto; */
  height: 26px;
  /* margin-right: 15px; */
}

.nav-icon {
  display: contents;
}

.main-menu:hover .nav-icon {
  display: block !important;
}

.nav-icon svg {
  width: 100%;

  /* height: auto; */
  height: 20px;
  /* margin-right: 15px; */
}

.sub-menu {
  display: none;
}

.main-menu:hover .sub-menu {
  /* display: block; */
  display: block !important;
  position: absolute;
  /* left: 70px; */
  left: 80px;
  width: 250px;
  /* top: 75%; */
  top: 44px;

  overflow: hidden;
  min-width: auto;
  width: 74.5%;
  margin: 0px 0px;
  padding: 0px 0px 12px 0px;
  z-index: 5;
  background: #fafafa;
  border-radius: 0px;
}

.sub-menu-links {
  display: flex;
  flex-direction: column;
}

.sub-menu-items {
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* margin-bottom: 10px; */
  /* color: #8F8F8F; */
  color: #000;
}

.sub-menu-items.active {
  color: #c00eae;
}

.sub-icons svg {
  width: 22.81px;
  height: 26.09px;
  /* margin-right: 15px; */
  margin-left: 15px;
}

.sub-menu-heading {
  font-weight: 600;
  font-size: 22px;
  line-height: 41.23px;
  font-family: "Poppins", sans-serif;
  margin-left: 15px;
}

.ml-collapse {
  margin-left: 0 !important;
}

.side-bar-nav-icons {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-right: 10px;
}

.side-bar-nav-icons svg {
  width: 8px;
  height: 10px !important;
  color: #202020;
}

.sidebar-content-container {
  position: relative;
  align-content: flex-start;
  height: calc(100% - 80px);
}

.sidebar-collapsed {
  /* margin-top: 40px;
  width: 100px; */
  /* position: fixed; */
overflow-y: scroll;
overflow-x: hidden;
/* margin-bottom: -25px; */
  
  max-width: 120px;
  box-sizing: border-box;
  overflow: visible;
  /* transition: all 0.5s ease-in-out; */
  background: #ffffff;
  height: 100%;
  z-index: 9999999999999;

  box-shadow: 18px 4px 35px rgba(0, 0, 0, 0.02);
  left: 0px;
  top: 0;

  padding: 20px 30px;
}

.collapse-side-bar-sub-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}

.ham-burger-side-bar-collapse {
  width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  padding: 0rem;
  color: #202020;
  font-size: 1.5rem;
  flex-shrink: 0;
  margin-left: 1.25rem;
  border: 0;
  cursor: pointer;
}

.collapse-side-bar-sub-menu .logo {
  text-align: center;
  position: relative;
  /* transition: all 0.5s ease-in-out; */
  /* margin-bottom: 40px; */
  /* flex-shrink: 0 !important; */
  gap: 15px;
}

.sidebar-logo-container-collapse {
  position: relative;
  text-align: center;
  /* padding: 0px 20px; */
  /* margin-bottom: 40px; */
  width: 100%;
  max-width: 100%;
}

.main-menu {
  cursor: pointer;
  padding: 0px;
  /* margin: 0px; */
  margin: 10px;

  font-size: 1rem;
  line-height: 1.875rem;
  list-style: none;
  position: relative;
  font-family: "Public Sans", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.875rem;
  text-decoration: none;
  text-align: center;
  color: #202020;
  display: flex;
  align-items: center;
  /* padding: 15px 8px; */
  /* padding: 10%; */
  transition: width 0.3s ease-in-out, overflow 0.3s ease;
}

.main-menu:hover {
  width: 310px;
  overflow: visible;
  z-index: 9999999;
}

.submenu-opener {
  display: none;
  text-align: start;
}

.main-menu:hover .submenu-opener {
  display: block;
  /* position: absolute;
    right: 0;
    top: 0;
    display: block;
    border: none;
    height: 50px;
    width: 40px;
    line-height: 52px;
    text-align: center;
    color: #A5A5A5;
    cursor: pointer;
    z-index: 5; */
}

.menu-section-title {
  border-bottom: 1px solid #f0f0f0;
}

.li-data {
  border: 0;
  list-style: none;
  display: block;
  position: relative;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5rem;
  width: 100%;
  border-radius: 0.5rem;
  overflow: hidden;
  margin: 4px 0px;
  color: rgb(165 165 164) !important;
  background-color: rgb(165 165 164);
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 10px !important;
  margin-top: 20px !important;
  height: 1px;
}

.collapse-icon {
  order: 0;
  list-style: none;
  display: block;
  position: relative;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5rem;
  width: 100%;
  border-radius: 0.5rem;
  overflow: hidden;
  margin: 4px 0px;
  box-sizing: border-box;
  min-height: 40px;
  background-color: #f6eeff;

  display: flex;
  justify-content: center;
  align-items: center;
}

.collapse-inside-icon svg {
  width: 100%;
  border-radius: 0.5rem;
  color: #c00eae;
  background-color: #f6eeff;
  transition: none;

  text-align: center;
  display: flex;
  /* padding: 8px 22px; */
  transition: all 0.5s ease-in-out;
  position: relative;
  text-align: center;
  margin-right: 0;
}

/* Styles for mobile devices (up to 768px wide) */
@media only screen and (max-width: 768px) {
  .sidebar-container {
    display: none;
  }
  .sidebar-collapsed{
    display: none;
  }

  .mobile-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .sidebar-container-mobile {
    width: 100%;
    height: 50px;
    margin: 0 auto;
    display: flex;

    justify-content: space-between;
    padding: 6px calc(16px / 2) 0 0;
    align-items: center;
    background: #fff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    bottom: 0;
    position: fixed;
    z-index: 50;
    border-inline-end-width: 2px;
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
    top: 0;
  }
}


/* Styles for tablets (between 768px and 1024px wide) */
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .sidebar-container-mobile {
    display: none;
  }
  .sidebar-container{
    display: none;
  }
     .sidebar-collapsed {
       display: none;
     }
.sidebar-container-mobile_responsive {
  /* width: 300px; */
  width: 300px;
  height: auto;
  background: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  bottom: 0;
  position: fixed;
  top: 4.5% !important;
  z-index: 1;
  border-inline-end-width: 2px;
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
  /* top: 0; */
}
             .mobile-header-container {
               display: flex;
               justify-content: space-between;
               align-items: center;
             }
            .sidebar-container-mobile {
              width: 100%;
              height: 50px;
              margin: 0 auto;
              display: flex;
      
              justify-content: space-between;
              padding: 6px calc(16px / 2) 0 0;
              align-items: center;
              background: #fff;
              box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
              bottom: 0;
              position: fixed;
              z-index: 50;
              border-inline-end-width: 2px;
              display: block;
              overflow-y: auto;
              overflow-x: hidden;
              top: 0;
            }
}

/* Styles for desktops (1025px and above) */
@media only screen and (min-width: 1025px) {


  .sidebar-container-mobile {
    display: none;
  }
  .sidebar-container-mobile_responsive{
    display: none;
  }
}