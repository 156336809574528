.json-viewer {
    font-family: 'Courier New', monospace;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
}

.indentation-controls {
    margin-bottom: 10px;
}

.json-node {
    margin-left: 20px;
}

.json-header {
    display: flex;
    align-items: center;
}

.collapse-btn {
    cursor: pointer;
    margin-right: 5px;
    background-color: transparent;
    border: none;
    font-size: 14px;
}

.json-body {
    margin-left: 20px;
    padding-left: 20px;
}

.json-body>div {
    padding-left: 10px;
}

.json-body span {
    color: #333;
}

strong {
    color: #007bff;
}

select {
    padding: 5px;
    margin-left: 10px;
}